import React, { Fragment, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './locales';
import messages from './messages';

interface ILocaleProvider {
    children: ReactNode,
    locale: string
}

export const LocaleProvider = ({ children, locale = LOCALES.SPANISH }: ILocaleProvider) =>
    <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={messages[locale]}
    >
        {children}
    </IntlProvider>