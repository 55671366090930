import { LOCALES } from "../locales";

export default {
    [LOCALES.SPANISH]: {
        'language_spanish': 'Español',
        'language_english': 'Inglés',
        'language_portuguese': 'Portugués',
        'welcome_wifi_title': 'Accede a la red Wifi',
        'get_in_btn': 'ENTRAR',
        'email_label': 'Correo Electrónico',
        'email_repeat_label': 'Repetir Correo Electrónico',
        'name_label': 'Nombre',
        'terms_and_conditions': 'Acepto los términos y condiciones',
        'register': 'Registrarme',
        'password': 'Contraseña',
        'password_repeat': 'Repetir Contraseña'
    }
}