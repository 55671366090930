import { LOCALES } from "../locales";

export default {
    [LOCALES.ENGLISH]: {
        'language_spanish': 'Spanish',
        'language_english': 'English',
        'language_portuguese': 'Portuguese',
        'welcome_wifi_title': 'Access the Wi-Fi network',
        'get_in_btn': 'GET IN',
        'email_label': 'Email',
        'email_repeat_label': 'Repeat Email',
        'name_label': 'Name',
        'terms_and_conditions': 'I accept the terms and conditions',
        'register': 'Register',
        'password': 'Password',
        'password_repeat': 'Repeat Password'
    }
}