import { LOCALES } from "../locales";

export default {
    [LOCALES.PORTUGUESE]: {
        'language_spanish': 'Espanhol',
        'language_english': 'Inglês',
        'language_portuguese': 'Português',
        'welcome_wifi_title': 'Acesse a rede wi-fi',
        'get_in_btn': 'ENTREM',
        'email_label': 'Correio eletrônico',
        'email_repeat_label': 'Repetir Correio eletrônico',
        'name_label': 'Nome',
        'terms_and_conditions': 'Eu aceito os termos e condições',
        'password': 'Senha',
        'password_repeat': 'repetir a senha',
        'register': 'Registarme',
    }
}